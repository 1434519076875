import Navigation from '../Navigation';
import { useTranslation } from 'react-i18next';

import './Home.scss';

const Home = () => {
	const {t} = useTranslation();
	
	return (
		<>
			<Navigation crumbs={[{name: t("data.crumbs.home"), to: "/"}]}/>
			<section id="pageContent">
				<div>
					<h1>{t("data.screens.home.title")}</h1>
					<p>{t("data.screens.home.intro")}</p>
		
					<h1>{t("data.screens.home.howItWorksHeader")}</h1>
					<p>{t("data.screens.home.howItWorks")}</p>
		
					<h2>{t("data.screens.home.readTermsHeader")}</h2>
					<p>{t("data.screens.home.readTerms")}</p>
		
					<h2>{t("data.screens.home.createAccountHeader")}</h2>
					<p>{t("data.screens.home.createAccount")}</p>
		
					<h2>{t("data.screens.home.createCollectionHeader")}</h2>
					<p>{t("data.screens.home.createCollection")}</p>
					
					<h2>{t("data.screens.home.maintainCollectionHeader")}</h2>
					<p>{t("data.screens.home.maintainCollection")}</p>
		
					<h2>{t("data.screens.home.otherHeader")}</h2>
					<p>{t("data.screens.home.other")}</p>
				</div>
			</section>
		</>
	);
};

export default Home;