import Collection from './Collection';
import { Auth } from 'aws-amplify';

class Friend {
	#id;
	#name;
	#collections = [];
	
	static fromJSON = (friend) => {
//		if (!friend.id || !friend.name || !friend.collections || !friend.collections.length <= 0) {
//			throw new Error("Id, name and collection(s) are mandatory");
//		}
		
		const convertedFriend = new Friend();
		convertedFriend.#id = friend.id;
		convertedFriend.#name = friend.name;
		
		friend.collections.forEach(collection => {
			if (collection.version !== '1.0') {
				convertedFriend.#collections.push(Collection.fromJSON(collection));
			}
		});
		
		return convertedFriend;
	};
	
	toJSON = () => {
		const jsonResult = {
			id: this.#id,
			name: this.#name,
			collections: []
		}
		
		this.#collections.forEach(collection => {
			jsonResult.collections.push(collection.toJSON());
		});
		
		return jsonResult;		
	};

	getId = () => this.#id;
	getName = () => this.#name;
	getCollections = () => this.#collections;
	
	static createCode = async () => {
		try {
			const token = await Auth.currentSession();

			const result = await fetch("https://api.v2.eurocoinhunt.eu/users/me/friends", {
				headers: new Headers({
					'Accept': 'application/json',
					'Content-Type': 'application/json',
                	'Authorization': token.getIdToken().getJwtToken()
				}),
				method: "PUT",
				cache: 'no-cache'
			})
			if (result.ok) {
				return result.text;
			} else {
				throw new Error("Could not send request to friend.");
			}
		} catch(error) {
			throw new Error("Could not send request to friend, " + error);
		}
	};
}

export default Friend;