import Navigation from '../Navigation';
import { useTranslation } from 'react-i18next';

import './Terms.scss';

const Terms = () => {
	const {t} = useTranslation();
	
	return (
		<>
			<Navigation crumbs={[{name: t("data.crumbs.home"), to: "/"}, {name: t("data.crumbs.term"), to: "/terms"}]}/>
			<section id="pageContent">
				<div>
					<h1>{t("data.screens.terms.title")}</h1>
					<p>
						{t("data.screens.terms.term1")}
					</p>
					<br/>
					<ul className="collection">
						<li className="collection-item">{t("data.screens.terms.term2")}</li>
						<li className="collection-item">{t("data.screens.terms.term3")}</li>
						<li className="collection-item">{t("data.screens.terms.term4")}</li>
						<li className="collection-item">{t("data.screens.terms.term5")}</li>
						<li className="collection-item">{t("data.screens.terms.term6")}</li>
						<li className="collection-item">{t("data.screens.terms.term7")}</li>
						<li className="collection-item">{t("data.screens.terms.term8")}</li>
						<li className="collection-item">{t("data.screens.terms.term9")}</li>
						<li className="collection-item">{t("data.screens.terms.term10")}</li>
						<li className="collection-item">{t("data.screens.terms.term11")}</li>
						<li className="collection-item">{t("data.screens.terms.term12")}</li>
						<li className="collection-item">{t("data.screens.terms.term13")}</li>
						<li className="collection-item">{t("data.screens.terms.term14")}</li>
					</ul>
				</div>
			</section>
		</>
	);
};

export default Terms;