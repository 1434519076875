import {StrictMode} from 'react';
import { createRoot } from 'react-dom/client';

import './i18n';
import App from './App';

import './index.scss';
import './fonts/LeckerliOne-Regular.ttf';
import 'bootstrap/dist/css/bootstrap.min.css';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
	<StrictMode>
		<App />
	</StrictMode>
);