import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { InputGroup, FormControl, Button, Container, Row, Col, Spinner, Modal } from 'react-bootstrap';
import { Download, Trash } from 'react-bootstrap-icons';

import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsConfiguration from '../../../config/awsConfiguration';

import { useMutation } from "react-query";

import { useTranslation } from 'react-i18next';

import Countries from './Countries';
import Coins from './Coins';
import Commemmorative from './Commemmorative';
import Coin from './Coin';
import Navigation from '../../Navigation';
  
import './Collections.scss';
import '@aws-amplify/ui-react/styles.css';


Amplify.configure(awsConfiguration);

const Collections = ({data}) => {
	return (
		<>
			<Routes>
				<Route exact path='/' element={<MyCollections data={data} />}/>
				<Route exact path='/:collectionId/countries' element={<Countries data={data} />}/>
				<Route exact path='/:collectionId/countries/:countryId/coins' element={<Coins data={data} />}/>
				<Route exact path='/:collectionId/countries/:countryId/coins/COM' element={<Commemmorative data={data} />}/>
				<Route exact path='/:collectionId/countries/:countryId/coins/:coinId' element={<Coin data={data} />}/>
			</Routes>
		</>
	);
};

const MyCollections = ({data}) => {
	const { register, handleSubmit } = useForm();
	const [ collectionToDelete, setCollectionToDelete ] = React.useState(false);
	const [ collectionToDownload, setCollectionToDownload ] = React.useState(false);
	
	const collectionCreation = useMutation(newCollection => new Promise(async (resolve) => {
		await data.collections.create(newCollection.name);
		
		resolve(data);
	}));
	
   	const collectionDeletion = useMutation(collection => new Promise(async (resolve) => {
		setCollectionToDelete(false);

		await data.collections.delete(collection.getId());
		
		resolve(data);
	}));
   	
	const {t} = useTranslation();
	
	const navigate = useNavigate();
	
	return (
		<>
			<Navigation crumbs={[{name: t("data.crumbs.home"), to: "/"}, {name: t("data.crumbs.collections")}]}/>
			<section id="pageContent">
				<div id="collectionsOverview">
					<h1>{t("data.screens.collections.title")}</h1>
					<Container fluid className="collectionsOverview">
						{data.collections.count() <= 0 &&
							<Row className="overviewBody"><Col>{t("data.screens.collections.noCollectionsYet")}</Col></Row>
						}
						{data.collections.count() > 0 &&
							Object.values(data.collections.get()).map(collection =>
								<Row className="overviewBody clickable" key={collection.getId()}>
									<Col onClick={() => navigate("/collections/" + collection.getId() + "/countries")}>
										<Container fluid>
											<Row>
												<Col sm={6}>{t("data.screens.collections.name")}: {collection.getName()}</Col>
												<Col sm={3}>{t("data.screens.collections.amountCoins")}: {collection.getCount()}</Col>
												<Col sm={3}>{t("data.screens.collections.value")}: &euro; {(collection.getValue()/100).toFixed(2)}</Col>
											</Row>
										</Container>
									</Col>
									<Col className="deleteButton textCentered" onClick={() => setCollectionToDelete(collection)} sm={1}><Trash /></Col>
								</Row>
							)
						}
					</Container>
				</div>
				<div id="newCollectionForm">
					<h1>{t("data.screens.collections.createNewCollectionTitle")}</h1>
					<form onSubmit={handleSubmit(collectionCreation.mutate)}>
						<InputGroup className="mb-3">
							<FormControl {...register('name')} placeholder={t("data.screens.collections.newCollectionInput")} aria-label={t("data.screens.collections.newCollectionInput")} aria-describedby="basic-addon2" />
							<Button id="button-addon2" type="submit">{t("data.screens.collections.create")}</Button>
						</InputGroup>
					</form>
				</div>
				{data.friends.count() > 0&&
					<div id="friendsCollections">
						<h1>{t("data.screens.collections.friends")}</h1>
						{
							data.friends.get().map((friend => {
								return <div key={friend.getName()}>
									<h2>{friend.getName()}</h2>
									<Container fluid className="collectionsOverview">
										{
											friend.getCollections().map((friendCollection) => {
												friendCollection["owner"] = friend.getName();
												return <Row className="overviewBody " key={friendCollection.getId()}>
													<Col>
														<Container fluid>
															<Row>
																<Col sm={6}>{t("data.screens.collections.name")}: {friendCollection.getName()}</Col>
																<Col sm={3}>{t("data.screens.collections.amountCoins")}: {friendCollection.getCount()}</Col>
																<Col sm={3}>{t("data.screens.collections.value")}: &euro; {(friendCollection.getValue()/100).toFixed(2)}</Col>
															</Row>
														</Container>
													</Col>
												</Row>
											})
										}
									</Container>
								</div>;
							}))
						}
					</div>
				}
			</section>

			{collectionToDelete &&
				<Modal show={collectionToDelete} onHide={() => setCollectionToDelete(false)}>
					<Modal.Header closeButton>
						<Modal.Title>{collectionToDelete.getName()}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t("data.screens.collections.sureToDelete")}
					</Modal.Body>
					<Modal.Footer>
	          			<Button variant="primary" onClick={() => {setCollectionToDelete(false)}}>{t("data.screens.collections.dontDelete")}</Button>
	          			<Button variant="secondary" onClick={() => collectionDeletion.mutate(collectionToDelete)}>{t("data.screens.collections.delete")}</Button>
					</Modal.Footer>
				</Modal>
			}
			
			{collectionToDownload &&
				<Modal show={collectionToDownload} onHide={() => setCollectionToDownload(false)}>
					<Modal.Header closeButton>
						<Modal.Title>{collectionToDownload.getName()}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						
					</Modal.Body>
				</Modal>
			}

			<Modal show={collectionCreation.isLoading || collectionDeletion.isLoading} size="sm">
				<Modal.Body>
					<div className="centered"><Spinner animation="border" variant="primary" /></div>
					<div className="centered">{t("data.screens.loadingData")}</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default withAuthenticator(Collections);