import {createContext, useContext, useState, useCallback} from "react";
import { v4 as uuidv4 } from 'uuid';

import { Toast } from 'react-bootstrap';

import './Toast.scss';

let ToastContext = createContext();

export const ToastContainer = ({children}) => {
	const [toasts, setToasts] = useState([]);
	
	const styles= {
		SUCCESS: "success",
		DANGER: "danger",
		WARNING: "warning",
		DEFAULT: "secondary"
	} 
	
  	const addToast = useCallback((title, body, style) => setToasts(toasts => [...toasts, {
		id: uuidv4(),
		title: title,
		body: body,
		style: style
	}]), [setToasts]);
		
	const removeToast = useCallback(toastId => setToasts(toasts => toasts.filter(toast => toastId !== toast.id)), [setToasts]);
	
	const contextValue = {styles, addToast};
	
	return (
		<ToastContext.Provider value={contextValue}>
			<div className="toastContainer">
				{
					toasts.map(toast => {
						if (toast.style === styles.SUCCESS) {
							return <Toast key={toast.id} onClose={() => removeToast(toast.id)} delay={3000} autohide bg={toast.style}>
								<Toast.Body>{toast.body}</Toast.Body>
							</Toast>;
						} else if (toast.style === styles.WARNING) {
							return <Toast key={toast.id} onClose={() => removeToast(toast.id)} bg={toast.style}>
								<Toast.Header>{toast.title}</Toast.Header>
								<Toast.Body>{toast.body}</Toast.Body>
							</Toast>;
						}

						return <></>;
					})
				}
			</div>
			{children}
		</ToastContext.Provider>
	);
}

export const useToasts = () => useContext(ToastContext);