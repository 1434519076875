import StaticDataStorage from './StaticData/StaticDataStorage';
import UserDataStorage from './UserData/UserDataStorage';

/** Class representing storage of static and user data. */
class Storage {
	#staticDataStorage = new StaticDataStorage();
	#userDataStorage = new UserDataStorage();
	
	static version = {
		verify: (version) => {
			const currentVersion = localStorage.getItem('version');
			
			if (version !== currentVersion) {
				localStorage.clear();
				sessionStorage.clear();
				localStorage.setItem('version', version);
			}
		}
	}
	
	/**
	 * Fetching all static data and user data.
	 */
	fetch = async () => {
		try {
			await this.#staticDataStorage.fetch();
			await this.#userDataStorage.fetch();
		} catch (error) {
			throw error;
		}
	};
	
	coins = {
		get: (countryId) => this.#staticDataStorage.coins.get(countryId)
	};
	
	collections = {
		count: () => this.#userDataStorage.collections.count(),
		
		get: () => this.#userDataStorage.collections.get(),
		
		getById: (collectionId) => this.#userDataStorage.collections.getById(collectionId),
		
		store: (collection) => this.#userDataStorage.collections.store(collection)
	};
	
	countries = {
		get: () => this.#staticDataStorage.countries.get()
	};
	
	friends = {
		count: () => this.#userDataStorage.friends.count(),
		
		get: () => this.#userDataStorage.friends.get()
	};
	
	messages = {
		get: () => this.#userDataStorage.messages.get()
	};
}

export default Storage;