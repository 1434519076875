import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';

import Navigation from '../../Navigation';

import './Friends.scss';
import '@aws-amplify/ui-react/styles.css';

const Friends = ({data}) => {
	return (
		<>
			<Routes>
				<Route exact path='/' element={<MyFriends data={data} />}/>
			</Routes>
		</>
	);
};

const MyFriends = ({data}) => {
	const {t} = useTranslation();
	
	return (
		<>
			<Navigation crumbs={[
				{name: t("data.menu.home"), to: "/"},
				{name: t("data.menu.friends")}]}/>
			<section id="pageContent">
				<div id="friendsOverview">
					<h1>{t("data.screens.friends.title")}</h1>
					<Container fluid className="overview">
						<Row className="overviewHeader">
							<Col sm={8}>{t("data.screens.friends.name")}</Col>
							<Col sm={2} />
						</Row>

						{data && data.friends && data.friends.count() <= 0 &&
							<Row className="overviewBody"><Col>{t("data.screens.friends.noFriendsYet")}</Col></Row>
						}
						{data && data.friends && data.friends.count() > 0 &&
						Object.values(data.friends.get()).map(friend => {
							return <Row className="overviewBody clickable" key={friend.getId()}>
								<Col sm={8}>{friend.getName()}</Col>
								<Col className="deleteButton textCentered" sm={2}></Col>
							</Row>											
						})
					}
					</Container>
				</div>					
			</section>
		</>
	);
};

export default withAuthenticator(Friends);