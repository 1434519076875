import { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { CheckCircle, Circle } from 'react-bootstrap-icons';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import Navigation from '../../Navigation';
import { useToasts } from '../../Toast';

import './Coin.scss';

const baseUrl = 'https://data.eurocoinhunt.eu';
	
const Coin = ({data}) => {
	// Alle teksten moeten uit een vertaling komen. Er mogen geen teksten meer in dit bestand staan.
	const {t} = useTranslation();
	// Als een munt al aanwezig is en er wordt op geklikt, moet een modal worden geopend met de details van de munt.
	const [coinDetails, setModalDetails] = useState(null);
	// Alle drie de urlParams worden gebruikt.
	const {collectionId, countryId, coinId} = useParams();
	// De geselecteerde verzameling wordt bepaald aan de hand van de urlParam.
	const collection = data.collections.getById(collectionId);
	// Alle mogelijke munten. Opgehaald uit een bestand van https://data.eurocoinhunt.eu.
	const availableCoins = data.coins.get(countryId);
	
	/**
	 * Voor het aanmaken van alle buttons
	 */
	const createButtons = (coin) => {
		return <div id="coinButtonsContainer">
			{
				availableCoins.coins[coin.type].map(availableCoin => {
					coin["year"] = availableCoin.year;
					// Als er varianten zijn, moeten deze allen een eigen button krijgen.
					if (availableCoin.variants) return createVariantButtons(availableCoin, {...coin})
					// Als er variaties zijn, moeten deze allen een eigen button krijgen.
					else if (availableCoin.variations) return createVariationButtons(availableCoin, {...coin});
					// Als het om 2 euro gaat zijn, moeten de verschillende randen een eigen button krijgen.
					else if (coin.type === "200") return createEdgeButtons({...coin})
					// En anders zijn het muntjes zonder verdere bijzonderheden.				
					else return createButton({...coin});
				})
			}
		</div>;
	}
	
	const createVariantButtons = (availableCoin, coin) => {
		return <div key={JSON.stringify(coin)}>
			{
				availableCoin.variants.map(variant => {
					// Als er variaties zijn, moeten deze allen een eigen button krijgen.
					if (variant.variations) return createVariationButtons(availableCoin, {...coin, variant: variant.variant});
					// Als het om 2 euro gaat zijn, moeten de verschillende randen een eigen button krijgen.
					else if (coin.type === "200") return createEdgeButtons({...coin, variant: variant.variant});
					// En anders zijn het muntjes zonder verdere bijzonderheden.
					else return createButton({...coin, variant: variant.variant});
				})
			}
		</div>;
	};
	
	const createVariationButtons = (availableCoin, coin) => {
		const buttons = [];
		
		if (coin.country !== "GR") {
			// Als het om 2 euro gaat zijn, moeten de verschillende randen een eigen button krijgen.
			if (coin.type === "200") buttons.push(...createEdgeButtons({...coin, class: "deprecated"}));
			// En anders zijn het muntjes zonder verdere bijzonderheden.
			else buttons.push(createButton({...coin, class: "deprecated"}));
		}
		
		let variations = availableCoin.variations;
		if (coin.country === "DE") variations = availableCoin.variants.find(coinToFind => coinToFind.variant === coin.variant).variations;		
		
		buttons.push(variations.map(variation => {
			// Als het om 2 euro gaat zijn, moeten de verschillende randen een eigen button krijgen.
			if (coin.type === "200") return createEdgeButtons({...coin, variation: variation});
			// En anders zijn het muntjes zonder verdere bijzonderheden.
			else return createButton({...coin, variation: variation});
		}));
		
		return buttons;
	};
	
	const createEdgeButtons = (coin) => {
		// 2 eurmunten uit Estland hebben geen verschillende randschriften. De rest wel.
		if (countryId === "EE") return createButton(coin);
		// En anders zijn het muntjes zonder verdere bijzonderheden.
		else return ["D", "R"].map(edge => createButton({...coin, edge: edge}));
	}
	
	const createButton = (coin) => {
		return <CoinButton data={data} collection={collection} coin={{...coin}} key={JSON.stringify(coin)} modalCallback={setModalDetails}/>;
	}
	
	return <>
		<Navigation crumbs={[
			{name: t("data.menu.home"), to: "/"}, 
			{name: t("data.menu.collections"), to: "/collections"}, 
			{name: collection.getName(), to: "/collections/" + collectionId + "/countries"}, 
			{name: t("data.general.countries." + countryId + ".name"), to: "/collections/" + collectionId + "/countries/" + countryId + "/coins"}, 
			{name: "€ " + (coinId/100).toFixed(2)}]}/>
				
		<section id="pageContent" className="row">
			<div id="countryContainer">{t("data.general.countries." + countryId + ".name")}, € {(coinId/100).toFixed(2)}</div>
			<div id="seriesContainer">
				{
					Object.keys(availableCoins.series).map(serieKey => {
						return <img alt={t("data.general.countries." + countryId + ".name") + ", serie " + serieKey + ", € " + (coinId/100).toFixed(2)} title={t("data.general.countries." + countryId + ".name") + ", serie " + serieKey + ", € " + (coinId/100).toFixed(2)} src={ baseUrl + availableCoins.series[serieKey].images[coinId]} key={serieKey} />
					})
				}
			</div>
			{
				createButtons({country: countryId, type: coinId, value: coinId})
			}
		</section>
		{coinDetails &&
			<CoinModal data={data} collection={collection} coin={coinDetails.coin} friends={data.friends.get()} callbacks={{
				closeModal: () => setModalDetails(null),
				hideCoin: () => coinDetails.callback()
			}}/>
		}
	</>;	
}

const CoinButton = ({data, collection, coin, modalCallback, disabled}) => {
	// Is de munt gevonden in de verzameling?
	const [found, setFound] = useState(collection.coins.findIndex({...coin}) >= 0);
	// Alle teksten moeten uit een vertaling komen. Er mogen geen teksten meer in dit bestand staan.
	const {t} = useTranslation();
	const {styles, addToast} = useToasts();

	let coinCode = coin.country + "." + coin.value + "." + coin.year;
	if (coin.variant) coinCode += ".V" + coin.variant;
	if (coin.edge) coinCode += ".E" + coin.edge;
	if (coin.variation) coinCode += ".T" + coin.variation;
	
	const toggleCoinInCurrentCollection = async () => {
		if (found) {
			modalCallback({coin: {...coin}, callback: setFound});
		} else {
			addToast(t("data.screens.coin.toast.savingTitle"), t("data.screens.coin.toast.savingDescription") + coinCode, styles.SUCCESS);
			setFound(!found);
			if (collection.coins.add({...coin})) {
				try {
					await data.collections.store(collection);
					addToast(t("data.screens.coin.toast.savedTitle"), t("data.screens.coin.toast.savedDescription") + coinCode, styles.SUCCESS);
				} catch(error) {
					addToast(t("data.screens.coin.toast.savingErrorTitle"), t("data.screens.coin.toast.savingErrorDescription") + coinCode, styles.WARNING);
					setFound(!found);
				}
			} else {
				addToast(t("data.screens.coin.toast.savingErrorTitle"), t("data.screens.coin.toast.savingErrorDescription") + coinCode, styles.WARNING);
				setFound(!found);
			}			
		}
	}
	
	const checkedClass = found ? "checked" : "unchecked";
	
	let buttonClass = coin.class && coin.class === "deprecated" ? "btn-secondary" : "btn-primary";
	if (coin.class && coin.class === "deprecated" && !found) disabled = "disabled";
	
	return <button id={coinCode} disabled={disabled} type="button" className={"coinButton btn " + buttonClass + " " + checkedClass} onClick={() => {toggleCoinInCurrentCollection()}} key={JSON.stringify(coin)}>
		<div className="coinContent">
			<div className="coinButtonTitles">
				<div>{coin.year}</div>
				<div className="coinButtonSubTitles">
					{coin.edge && 
						<div>{t("data.screens.coin.modal.edge")}: {coin.edge === 'D' ? t("data.screens.coin.modal.edgeD") : t("data.screens.coin.modal.edgeR")}</div>
					}
					{coin.variant && 
						<div>{t("data.screens.coin.modal.variant")}: {t("data.general.countries." + coin.country + ".variants." + coin.variant)} ({coin.variant})</div>
					}
					{coin.variation && 
						<div>{t("data.screens.coin.modal.variation")}: {t("data.general.countries." + coin.country + ".variations." + coin.variation)} ({coin.variation})</div>
					}
				</div>
			</div>
			<div className="coinButtonCheckbox">
				{found&&
					<CheckCircle className="coincheckbox checked" size={30} />
				}
				{!found&& 
					<Circle className="coincheckbox unchecked" size={30} />
				}
			</div>
		</div>
	</button>;	
};

const CoinModal = ({data, collection, coin, friends, callbacks}) => {
	// Is de munt gevonden in de verzameling?
	const [, refresh] = useState();
	const {t} = useTranslation();
	const {styles, addToast} = useToasts();
	
	const collections = data.collections.get();	
	
	const getCheckbox = (currentCollection) => {
		const found = currentCollection.coins.findIndex({...coin}) >= 0;
		
		if (collection.getId() === currentCollection.getId()) {
			if (found) {
				return <CheckCircle className="coincheckbox clickable checked" size={30} onClick={async () => {
					let coinCode = coin.country + "." + coin.value + "." + coin.year;
					if (coin.variant) coinCode += ".V" + coin.variant;
					if (coin.edge) coinCode += ".E" + coin.edge;
					if (coin.variation) coinCode += ".T" + coin.variation;
	
					addToast(t("data.screens.coin.toast.removeTitle"), t("data.screens.coin.toast.removeDescription") + coinCode, styles.SUCCESS);
					if (collection.coins.remove({...coin})) {
						try {
							await data.collections.store(collection);
							addToast(t("data.screens.coin.toast.removedTitle"), t("data.screens.coin.toast.removedDescription") + coinCode, styles.SUCCESS);
							callbacks.hideCoin(false);
							refresh();
						} catch(error) {
							addToast(t("data.screens.coin.toast.savingErrorTitle"), t("data.screens.coin.toast.savingErrorDescription") + coinCode, styles.WARNING);
							refresh();
						}		
					} else {
						addToast(t("data.screens.coin.toast.savingErrorTitle"), t("data.screens.coin.toast.savingErrorDescription") + coinCode, styles.WARNING);
					}			
				}} />
			} else {
				return <Circle size={30} />
			}
		} else {
			if (found) {
				return <CheckCircle size={30} />
			} else {
				return <Circle size={30} />
			}
		}
	} 

	return (
		<Modal show="true" onHide={() => callbacks.closeModal()} size="lg" className="coinModal">
			<Modal.Header closeButton>
				<Modal.Title>{t("data.screens.coin.modal.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div><span>{t("data.screens.coin.modal.country")}:</span> {t("data.general.countries." + coin.country + ".name")}</div>
				<div><span>{t("data.screens.coin.modal.year")}:</span> {coin.year}</div>
				<div><span>{t("data.screens.coin.modal.value")}:</span> € {(coin.type/100).toFixed(2)}</div>
				{coin.edge && 
					<div><span>{t("data.screens.coin.modal.edge")}:</span> {coin.edge === 'D' ? t("data.screens.coin.modal.edgeD") : t("data.screens.coin.modal.edgeR")}</div>
				}
				{coin.variant && 
					<div><span>{t("data.screens.coin.modal.variant")}:</span> {t("data.general.countries." + coin.country + ".variants." + coin.variant)} ({coin.variant})</div>
				}
				{coin.variation && 
					<div><span>{t("data.screens.coin.modal.variation")}:</span> {t("data.general.countries." + coin.country + ".variations." + coin.variation)} ({coin.variation})</div>
				}
				
				<h1>{t("data.screens.coin.modal.collectionsHeader")}</h1>
				<table className="coinModalBody">
					<thead>
						<tr><th>{t("data.screens.coin.modal.owner")}</th><th>{t("data.screens.coin.modal.collectionName")}</th><th>{t("data.screens.coin.modal.owned")}</th></tr>
					</thead>
					<tbody>
						{
							Object.keys(collections).map(collectionId => {
								return <tr key={collectionId}>
									<td>{t("data.screens.coin.modal.you")}</td>
									<td>{collections[collectionId].getName()}</td>
									<td className="textCentered">
										{getCheckbox(collections[collectionId])}
									</td>
								</tr>;
							})
						}
						{
							friends.map(friend => friend.getCollections().map(collection => {
								const found = collection.coins.findIndex({...coin});
								
								return <tr key={friend.getId() + collection.getId()}>
									<td>{friend.getName()}</td>
									<td>{collection.getName()}</td>
									<td className="textCentered">{
										found > -1 ? <CheckCircle size={30} /> : <Circle size={30} />	
									}</td>
								</tr>;
							}))
						}
					</tbody>
				</table>
			</Modal.Body>
		</Modal>
	);
}

export default withAuthenticator(Coin);