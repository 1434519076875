import { Auth } from 'aws-amplify';

/** Class representing storage for user data localy. */
class UserDataLocalStorage {
	#storage = sessionStorage;
	#userInfo = null;
	
	/**
	 * Fetching all data for current user that is stored locally. For now that means that all Collections, Friends and Messages are fetched.
	 *
	 * @async
	 * @returns All collections, friends and messages.
	 */
	fetch = async () => {
		this.#userInfo = await Auth.currentUserInfo();
		return [this.collections.fetch(), this.friends.fetch(), this.messages.fetch()];
	}
	
	/**
	 * Storing all collections, friends and messages locally.
	 *
	 * @param collections All collections to store locally.
	 * @param friends All friends to store locally.
	 * @param messages All messages to store locally.
	 */
	store = (collections, friends, messages) => {
		this.collections.store(collections);
		this.friends.store(friends);
		this.messages.store(messages);
	}
	
	/**
	 * Functions for local storage of collections.
	 */
	collections = {
		count: () => {
			return this.collections.fetch().length;
		},
		
		store: (collections) => this.#storage.setItem(this.#userInfo.username + "_collections", JSON.stringify(collections)),
		
		/**
		 * Storing all collections locally.
		 *
		 * @param collections All collections to store locally.
		 */
		storeOne: (collection) => {
			const collections = this.collections.fetch();
			const collectionIndex = collections.findIndex(storedCollection => collection.id === storedCollection.id);
			
			collections[collectionIndex] = collection;
			this.collections.store(collections);			
		},
		
		/**
		 * Fetching all collections that are stored locally.
		 *
		 * @returns All collections
		 */
		fetch: () => JSON.parse(this.#storage.getItem(this.#userInfo.username + "_collections"))
	}
	
	/**
	 * Functions for local storage of friends.
	 */
	friends = {
		count: () => {
			return this.friends.fetch().length;
		},
		
		/**
		 * Storing all friends locally.
		 *
		 * @param friends All friends to store locally.
		 */
		store: (friends) => {
			this.#storage.setItem(this.#userInfo.username + "_friends", JSON.stringify(friends));
		},

		/**
		 * Fetching all friends that are stored locally.
		 *
		 * @returns All friends
		 */
		fetch: () => {
			return JSON.parse(this.#storage.getItem(this.#userInfo.username + "_friends"));
		}
	}
	
	/**
	 * Functions for local storage of messages.
	 */
	messages = {
		/**
		 * Storing all messages locally.
		 *
		 * @param messages All messages to store locally.
		 */
		store: (messages) => {
			this.#storage.setItem(this.#userInfo.username + "_messages", JSON.stringify(messages));
		},
		
		/**
		 * Fetching all messages that are stored locally.
		 *
		 * @returns All messages
		 */
		fetch: () => {
			return JSON.parse(this.#storage.getItem(this.#userInfo.username + "_messages"));
		}
	}
}

export default UserDataLocalStorage;