import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import { MenuButtonWide, Mailbox, HouseFill, CurrencyEuro, PersonCircle, PeopleFill, FileEarmarkTextFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import './Navigation.scss';

const Navigation = ({crumbs}) => {
	const {t} = useTranslation();
	
	return (
		<nav>
			<div className="nav-wrapper">
				<Breadcrumbs crumbs={crumbs} className="left" />
				<Dropdown className="d-inline mx-2 right">
					<Dropdown.Toggle as="div" id="dropdown-autoclose-true" variant="Link">
						<MenuButtonWide id="menuButton"/>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item href="/"><HouseFill /> {t("data.menu.home")}</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item href="/collections"><CurrencyEuro /> {t("data.menu.collections")}</Dropdown.Item>
{/*						<Dropdown.Item href="/messages"><Mailbox /> {t("data.menu.messages")}</Dropdown.Item> */}
						<Dropdown.Item href="/friends"><PeopleFill /> {t("data.menu.friends")}</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item href="/account"><PersonCircle /> {t("data.menu.account")}</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item href="/terms"><FileEarmarkTextFill /> {t("data.menu.terms")}</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
	    	</div>
		</nav>
	);
}

const Breadcrumbs = ({crumbs}) => {
	return (
		<span id="breadcrumbs">
			{
				crumbs.map((crumb, index) => {
					if (index < (crumbs.length-1)) return (<span key={index}><Link to={crumb.to} className="crumb">{crumb.name}</Link> &bull; </span>);
					else if (crumb.to) return <Link key={index} to={crumb.to} className="crumb">{crumb.name}</Link>;
					else return <span key={index} className="crumb">{crumb.name}</span>;
				})
			}
		</span>
	)
}

export default Navigation;