import React from "react";
import { Modal, Spinner  } from 'react-bootstrap';

import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsConfiguration from '../../config/awsConfiguration';

import { useQuery } from "react-query";

import { useTranslation } from 'react-i18next';

import MyData from '../../model/MyData';
import ErrorMessage from '../ErrorMessage';

import '@aws-amplify/ui-react/styles.css';
import './Modal.scss';

import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('nl');

Amplify.configure(awsConfiguration);

const SecuredContent = ({children}) => {
	const {t} = useTranslation();
	const { isLoading, isError, data, error, refetch } = useQuery('myData', MyData.fetch);
	
    if (isLoading) {
        return <>
			<div className="centered"><Spinner animation="border" variant="primary" /></div>
			<div className="centered">{t("data.screens.loadingData")}</div>
		</>
    }
    
    if (isError) {
        return <ErrorMessage message={t("data.screens.error.infoCouldNotBeLoaded")} error={error} />;
    }
    
 	return <div>
    	{React.Children.map(children, (child) => React.cloneElement(child, { data: data }))}
    </div>;
}

const formFields = {
	signUp: {
		name: {
			order: 1,
			placeholder: "Naam / Name"
		},
		username: {
			order: 2
		},
		email: {
			order: 3,
			placeholder: "E-mailadres / Email address"
		},
		password: {
			order: 4
		},
		confirm_password: {
			order: 5
		}
	}
};

export default withAuthenticator(SecuredContent, { formFields: formFields });