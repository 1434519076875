import { useTranslation } from 'react-i18next';

import './Help.scss';

const Help = () => {
	const {t} = useTranslation();
	
	return (
		<div>
			<h1>{t("data.screens.help.title")}</h1>
			<div>{t("data.screens.help.description")}</div>

			<h2>{t("data.screens.help.gradesTitle")}</h2>
			<p>{t("data.screens.help.gradesDescription")}</p>
			
			<h2>{t("data.screens.help.variantsTitle")}</h2>
			<p>{t("data.screens.help.variantsDescription")}</p>

			<h2>{t("data.screens.help.variationsTitle")}</h2>
			<p>{t("data.screens.help.variationsDescription")}</p>
			<p>
				<b>{t("data.general.countries.DE.name")} (DE)</b><br/>
				{t("data.screens.help.variationsGermany")}
			</p>
			<p>
				<b>{t("data.general.countries.ES.name")} (ES)</b><br/>
				{t("data.screens.help.variationsSpain")}
			</p>		
			<p>
				<b>{t("data.general.countries.GR.name")} (GR)</b><br/>
				{t("data.screens.help.variationsGreece")}
			</p>
			<p>
				<b>{t("data.general.countries.LU.name")} (MC)</b><br/>
				{t("data.screens.help.variationsLuxembourg")}
			</p>
			<p>
				<b>{t("data.general.countries.MC.name")} (MC)</b><br/>
				{t("data.screens.help.variationsMonaco")}
			</p>
			<p>
				<b>{t("data.general.countries.NL.name")} (NL)</b><br/>
				{t("data.screens.help.variationsNetherlands")}
			</p>
			<p>
				<b>{t("data.general.countries.PT.name")} (NL)</b><br/>
				{t("data.screens.help.variationsPortugal")}
			</p>
			<p>
				<b>{t("data.general.countries.VA.name")} (NL)</b><br/>
				{t("data.screens.help.variationsVaticanCity")}
			</p>
			
			
			<h2>{t("data.screens.help.edgesTitle")}</h2>
			<p></p>
			<table>
				<tbody>
					<tr>
						<th>{t("data.screens.help.edgesCountry")}</th>
						<th>{t("data.screens.help.edgesDefault")}</th>
						<th>{t("data.screens.help.edgesReversed")}</th>
					</tr>
					<tr>
						<td>{t("data.general.countries.AD.name")} (AD)</td>
						<td>2 ★ ★ <span class="upsidedown">2</span> <span class="mirrored">★ ★</span></td>
						<td>2 <span class="mirrored">★ ★</span> <span class="upsidedown">2</span> ★ ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.AU.name")} (AU)</td>
						<td>2 EURO ★★★ <span class="upsidedown">2 EURO</span>	<span class="mirrored">★★★</span></td>
						<td>2 EURO <span class="mirrored">★★★</span> <span class="upsidedown">2 EURO</span> ★★★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.BE.name")} (BE)</td>
						<td>2 ★ ★ <span class="upsidedown">2</span> <span class="mirrored">★ ★</span></td>
						<td>2 <span class="mirrored">★ ★</span> <span class="upsidedown">2</span> ★ ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.CY.name")} (CY)</td>
						<td>2 EYPΩ 2 EURO 2 EYPΩ 2 EURO</td>
						<td><span class="upsidedown">2 EYPΩ 2 EURO 2 EYPΩ 2 EURO</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.DE.name")} (DE)</td>
						<td>EINIGKEIT UND RECHT UND FREIHEIT</td>
						<td><span class="upsidedown">EINIGKEIT UND RECHT UND FREIHEIT</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.EE.name")} (EE)</td>
						<td>E E S T I   O   <span class="upsidedown">E E S T I   O</span></td>
						<td></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.ES.name")} (ES)</td>
						<td>2 ★ ★ <span class="upsidedown">2</span> <span class="mirrored">★ ★</span></td>
						<td>2 <span class="mirrored">★ ★</span> <span class="upsidedown">2</span> ★ ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.FI.name")} (FI)</td>
						<td>SUOMI FINLAND * * *</td>
						<td><span class="upsidedown">SUOMI FINLAND * * *</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.FR.name")} (FR)</td>
						<td>2 ★ ★ <span class="upsidedown">2</span> <span class="mirrored">★ ★</span></td>
						<td>2 <span class="mirrored">★ ★</span> <span class="upsidedown">2</span> ★ ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.HR.name")} (HR)</td>
						<td>O LIJEPA O DRAGA O SLATKA SLOBODO</td>
						<td><span class="upsidedown">O LIJEPA O DRAGA O SLATKA SLOBODO</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.GR.name")} (GR)</td>
						<td>ΕΛΛΗΝΙΚΗ ΔΗΜΟΚΡΑΤΙΑ ★</td>
						<td><span class="upsidedown">ΕΛΛΗΝΙΚΗ ΔΗΜΟΚΡΑΤΙΑ ★</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.IE.name")} (IE)</td>
						<td>2 ★ ★ <span class="upsidedown">2</span> <span class="mirrored">★ ★</span></td>
						<td>2 <span class="mirrored">★ ★</span> <span class="upsidedown">2</span> ★ ★</td>
					</tr>
					<tr>
						<td>Italië (IT)</td>
						<td>2 ★ <span class="upsidedown">2</span> <span class="mirrored">★</span></td>
						<td>2 <span class="mirrored">★</span> <span class="upsidedown">2</span> ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.IT.name")} (LT)</td>
						<td>LAISVÉ * VIENYBÉ * GEROVÉ *</td>
						<td><span class="upsidedown">LAISVÉ * VIENYBÉ * GEROVÉ *</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.LU.name")} (LU)</td>
						<td>2 ★ ★ <span class="upsidedown">2</span> <span class="mirrored">★ ★</span></td>
						<td>2 <span class="mirrored">★ ★</span> <span class="upsidedown">2</span> ★ ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.LV.name")} (LV)</td>
						<td>DIEVS *** SVËTÏ *** LATVIJU ***</td>
						<td><span class="upsidedown">DIEVS *** SVËTÏ *** LATVIJU ***</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.MC.name")} (MC)</td>
						<td>2 ★ ★ <span class="upsidedown">2</span> <span class="mirrored">★ ★</span></td>
						<td>2 <span class="mirrored">★ ★</span> <span class="upsidedown">2</span> ★ ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.MT.name")} (MT)</td>
						<td>2 ★ ★ <span class="upsidedown">2</span> <span class="mirrored">★ ★</span></td>
						<td>2 <span class="mirrored">★ ★</span> <span class="upsidedown">2</span> ★ ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.NL.name")} (NL)</td>
						<td>GOD ★ ZIJ ★ MET ★ ONS ★</td>
						<td><span class="upsidedown">GOD ★ ZIJ ★ MET ★ ONS ★</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.PT.name")} (PT)</td>
						<td><sub>{t("data.screens.help.egedsPortugalRight")}</sub></td>
						<td><sub>{t("data.screens.help.egedsPortugalLeft")}</sub></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.SI.name")} (SI)</td>
						<td>S L O V E N I J A ⊛</td>
						<td><span class="upsidedown">S L O V E N I J A ⊛</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.SK.name")} (SK)</td>
						<td>SLOVENSKÁ REPUBLIKA</td>
						<td><span class="upsidedown">SLOVENSKÁ REPUBLIKA</span></td>
					</tr>
					<tr>
						<td>{t("data.general.countries.SM.name")} (SM)</td>
						<td>2 ★ <span class="upsidedown">2</span> <span class="mirrored">★</span></td>
						<td>2 <span class="mirrored">★</span> <span class="upsidedown">2</span> ★</td>
					</tr>
					<tr>
						<td>{t("data.general.countries.VC.name")} (VC)</td>
						<td>2 ★ <span class="upsidedown">2</span> <span class="mirrored">★</span></td>
						<td>2 <span class="mirrored">★</span> <span class="upsidedown">2</span> ★</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default Help;