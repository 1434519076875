import Navigation from './Navigation';
import { useTranslation } from 'react-i18next';

const ErrorMessage = (props) => {
	const {t} = useTranslation();
	
	return (
		<>
			<Navigation crumbs={[{name: t("data.crumbs.home"), to: "/"}, {name: t("data.crumbs.error")}]}/>
			<section id="pageContent" className="row">
				<div>
					<h3>{t("data.screens.error.message")}</h3>
					<div>{props.message}</div>
					<br/>
					<h3>{t("data.screens.error.technicalMessage")}</h3>
					<div>{props.error.message}</div>
				</div>
			</section>
		</>
	);
};

export default ErrorMessage;