import React from "react";
import { Link, useParams } from "react-router-dom";
import { Badge } from 'react-bootstrap';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';

import Navigation from '../../Navigation';

import './Coins.scss';

const CoinsObject = ({data}) => {
	const {collectionId, countryId} = useParams();
	const collection = data.collections.getById(collectionId);
	const collectionCoins = collection.coins.get()[countryId];
	const {t} = useTranslation();
	
	const coins = data.coins.get(countryId);
	const baseUrl = 'https://data.eurocoinhunt.eu';
	const baseImages = coins.series[Object.keys(coins.series)[Object.keys(coins.series).length-1]].images;

	const count = (value) => {
		if (!collectionCoins) return 0;
		if (!collectionCoins[value]) return 0;
		return collectionCoins[value].length;
	}
	
	return (
		<>
			<Navigation crumbs={[
				{name: t("data.crumbs.home"), to: "/"}, 
				{name: t("data.crumbs.collections"), to: "/collections"}, 
				{name: collection.getName(), to: "/collections/" + collectionId + "/countries"}, 
				{name: t("data.general.countries." + countryId + ".name")}
			]}/>
			<section id="pageContent">
				<div id="countryContainer">{t("data.general.countries." + countryId + ".name")}</div>
				<div id="coinsContainer" className="textCentered">
					{coins.coins.COM.length > 0&&
						<>
							<Link to='COM' className="coin textCentered">
								<div>
									<span className="card-title">{t("data.screens.coins.cardTitlecommemoratives")}</span>
									<div className="card-content">
										<div><img alt="commemmoratives" src={baseUrl + coins.coins.COM[0].image} /></div>
										<div><Badge bg="secondary">{count('COM')}</Badge></div>
									</div>
								</div>
							</Link>
							<br />
						</>
					}
					<Link to='200' className="coin textCentered">
						<div>
							<span className="card-title">{t("data.general.coins.200")}</span>
							<div className="card-content">
								<img alt="2.00" src={baseUrl + baseImages['200'] } />
								<Badge bg="secondary">{count('200')}</Badge>
							</div>
						</div>
					</Link>
					<Link to='100' className="coin textCentered">
						<div>
							<span className="card-title">{t("data.general.coins.100")}</span>
							<div className="card-content">
								<img alt="1.00" src={baseUrl + baseImages['100'] } />
								<Badge bg="secondary">{count('100')}</Badge>
							</div>
						</div>
					</Link>
					<Link to='050' className="coin textCentered">
						<div>
							<span className="card-title">{t("data.general.coins.050")}</span>
							<div className="card-content">
								<img alt="0.50" src={baseUrl + baseImages['050'] } />
								<Badge bg="secondary">{count('050')}</Badge>
							</div>
						</div>
					</Link>
					<Link to='020' className="coin textCentered">
						<div>
							<span className="card-title">{t("data.general.coins.020")}</span>
							<div className="card-content">
								<img alt="0.20" src={baseUrl + baseImages['020'] } />
								<Badge bg="secondary">{count('020')}</Badge>
							</div>
						</div>
					</Link>
					<Link to='010' className="coin textCentered">
						<div>
							<span className="card-title">{t("data.general.coins.010")}</span>
							<div className="card-content">
								<img alt="0.10" src={baseUrl + baseImages['010'] } />
								<Badge bg="secondary">{count('010')}</Badge>
							</div>
						</div>
					</Link>
					<Link to='005' className="coin textCentered">
						<div>
							<span className="card-title">{t("data.general.coins.005")}</span>
							<div className="card-content">
								<img alt="0.05" src={baseUrl + baseImages['005'] } />
								<Badge bg="secondary">{count('005')}</Badge>
							</div>
						</div>
					</Link>
					<Link to='002' className="coin textCentered">
						<div>
							<span className="card-title">{t("data.general.coins.002")}</span>
							<div className="card-content">
								<img alt="0.02" src={baseUrl + baseImages['002'] } />
								<Badge bg="secondary">{count('002')}</Badge>
							</div>
						</div>
					</Link>
					<Link to='001' className="coin textCentered">
						<div>
							<span className="card-title">{t("data.general.coins.001")}</span>
							<div className="card-content">
								<img alt="0.01" src={baseUrl + baseImages['001'] } />
								<Badge bg="secondary">{count('001')}</Badge>
							</div>
						</div>
					</Link>
				</div>
			</section>
		</>
	);
}

export default withAuthenticator(CoinsObject);