import { Routes, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import Home from './unsecured/Home';
import Help from './unsecured/Help';
import Terms from './unsecured/Terms';

import Collections from './secured/collections/Collections';
import Friends from './secured/friends/Friends';
import Messages from './secured/messages/Messages';
import Account from './secured/account/Account';
import SecuredContent from './secured/SecuredContent';

const queryClient = new QueryClient();

const Content = () => {
	return (
		<Routes>
			<Route exact={true} path="/" element={<Home />}/>
			<Route exact={true} path="/collections/*" element={<QueryClientProvider client={queryClient}><SecuredContent><Collections /></SecuredContent></QueryClientProvider>} />
			<Route exact={true} path="/friends/*" element={<QueryClientProvider client={queryClient}><SecuredContent><Friends /></SecuredContent></QueryClientProvider>} />
			<Route exact={true} path="/messages" element={<QueryClientProvider client={queryClient}><SecuredContent><Messages /></SecuredContent></QueryClientProvider>} />
			<Route exact={true} path="/account" element={<QueryClientProvider client={queryClient}><SecuredContent><Account /></SecuredContent></QueryClientProvider>} />
			<Route exact={true} path="/help" element={<Help />}/>
			<Route exact={true} path="/terms" element={<Terms />}/>
		</Routes>
	);
}

export default Content;