import React from "react";

import { withAuthenticator } from '@aws-amplify/ui-react';
import { Col, Container, Row } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import Navigation from '../../Navigation';

const Account = props => {
	const {t} = useTranslation();
	
	return (<>
		<Navigation crumbs={[{name: t("data.crumbs.home"), to: "/"}, {name: t("data.crumbs.account")}]}/>
		<section id="pageContent">
			<div id="friendsOverview">
				<h1>{t("data.screens.account.title")}</h1>
				<Container>
					<Row><Col sm={2}>{t("data.screens.account.username")}:</Col><Col>{props.user.username}</Col></Row>
					<Row><Col sm={2}>{t("data.screens.account.name")}:</Col><Col>{props.user.attributes.name}</Col></Row>
					<Row><Col sm={2}>{t("data.screens.account.email")}:</Col><Col>{props.user.attributes.email}</Col></Row>
				</Container>
			</div>
			<button type="button" className="coinButton btn" onClick={async () => {await Auth.signOut()}}>{t("data.screens.account.signout")}</button>
		</section>
	</>);
};

export default withAuthenticator(Account);