import React from "react";
import { Button, Col, Container, Row } from 'react-bootstrap';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { useTranslation } from 'react-i18next';

import Navigation from '../../Navigation';

import './Messages.scss';

const Messages = ({myData}) => {
	const {t} = useTranslation();
	
	return (
		<>
			<Navigation crumbs={[
				{name: t("data.menu.home"), to: "/"},
				{name: t("data.menu.messages")}]}/>
			<section id="pageContent">
				<div id="messagesOverview">
					<h1>{t("data.screens.messages.title")}</h1>
					<Container fluid className="overview">
						<Row className="overviewHeader">
							<Col sm={5}>{t("data.screens.messages.from")}</Col>
							<Col sm={5}>{t("data.screens.messages.title")}</Col>
							<Col sm={2}>{t("data.screens.messages.title")}</Col>
						</Row>

						{myData && myData.messages && myData.messages.count() <= 0 &&
							<Row className="overviewBody"><Col>{t("data.screens.messages.noMessagesYet")}</Col></Row>
						}
						{myData && myData.messages && myData.messages.count() > 0 &&
						Object.values(myData.messages.get()).map(message => 
							<Row className="overviewBody clickable" key={message.getId()}>
								<Col sm={5}>{message.getFrom()}</Col>
								<Col sm={5}>{message.getSubject()}</Col>
								<Col sm={2} className="deleteButton textCentered"></Col>
							</Row>											
						)
					}
					</Container>
				</div>					
			</section>
		</>
	);
};

export default withAuthenticator(Messages);