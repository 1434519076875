import UserDataLocalStorage from './UserDataLocalStorage';
import UserDataOnlineStorage from './UserDataOnlineStorage';

/** Class representing storage for user data. */
class UserDataStorage {
	#localStorage = new UserDataLocalStorage();
	#onlineStorage = new UserDataOnlineStorage();

	/**
	 * Fetching user data. First trying to fetch from local storage. If that is not available, then try to fetch online data.
	 * If online data is fetched, store it locally.
	 */
	fetch = async () => {
		let [collections, friends, messages] = await this.#localStorage.fetch();
		
		try {
			if (!collections || !friends || !messages) {
				[collections, friends, messages] = await this.#onlineStorage.fetch();
				this.#localStorage.store(collections, friends, messages);
			}
		} catch (error) {
			throw error;
		}
	};
	
	collections = {
		count: () => this.#localStorage.collections.count(),
		
		get: () => this.#localStorage.collections.fetch(),
		
		getById: (collectionId) => this.#localStorage.collections.fetch().find(collection => collectionId === collection.id ? collection : null),
		
		store: (collection) => {
			this.#onlineStorage.collections.store(collection);
			this.#localStorage.collections.storeOne(collection);
		}
	};
	
	friends = {
		count: () => this.#localStorage.friends.count(),
		
		get: () => this.#localStorage.friends.fetch(),
	};
	
	messages = {
		get: () => []
	};
}

export default UserDataStorage;