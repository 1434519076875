import React from "react";
import { Link, useParams } from "react-router-dom";
import { Badge } from 'react-bootstrap';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import Navigation from '../../Navigation';

import './Countries.scss';

const CountriesObject = ({data}) => {
	const {collectionId} = useParams();
	const collection = data.collections.getById(collectionId);
	const {t} = useTranslation();
	
	return (
		<>
			<Navigation crumbs={[{name: t("data.crumbs.home"), to: "/"}, {name: t("data.crumbs.collections"), to: "/collections"}, {name: collection.getName()}]}/>
			<section id="pageContent">
				<div id="collectionContainer" className="textCentered">
					<h1>{collection.getName()}</h1>
					<span>{t("data.screens.collection.numberOfCoins")}{collection.getCount()} - {t("data.screens.collection.totalValue")} {(collection.getValue()/100).toFixed(2)}</span>
				</div>
				<div id="countriesContainer">
					{data.countries.get().data.map(country => <CountryCard country={country} collection={collection} key={country.key}/>)}
				</div>
			</section>
		</>
	);
};

const CountryCard = ({country, collection}) => {
	const {t} = useTranslation();

	return (
		<Link to={country.key + "/coins" }>
			<div className="countryCard">
				<span className="card-title">{t('data.general.countries.' + country.key + '.name')}<br />({ country.key })</span>
				<div className="card-content">
					<span>
						<img id={ "flag_" + country.key } alt={country.key} className="flag" src={ "https://data.eurocoinhunt.eu/images/flags/" + country.key + ".png" } />
						<Badge bg="secondary">{collection.coins.countForCountry(country.key)}</Badge>
					</span>
				</div>
			</div>
		</Link>
	);
};

export default withAuthenticator(CountriesObject);