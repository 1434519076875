import { BrowserRouter as Router } from "react-router-dom";

import HeaderComponent from './components/Header';
import ContentComponent from './components/Content';
import { ToastContainer } from './components/Toast';
import Storage from './Storage/Storage';

const App = () => {
	Storage.version.verify('202305121126');
	
	return (
		<Router>
			<ToastContainer>
				<HeaderComponent />
				<ContentComponent />
			</ToastContainer>
		</Router>
	);
}

export default App;
