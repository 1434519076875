import axios from 'axios';

/** Class representing storage for static data. */
class StaticDataStorage {
	#baseUri = 'https://data.eurocoinhunt.eu';
	#storage = localStorage;

	#axiosCacheHeaders = {'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0'};

	/**
	 * Fetching all static data. For now it is about Countries and coins. 
	 */
	fetch = async () => {
		try {
			const response = await axios.get(this.#baseUri + '/data.json');
	
			await this.countries.fetch(response.data.countries);
			await this.coins.fetch(response.data.coins);
		} catch (error) {
			throw error;
		}
	};
	
	/**
	 * Functions for local storage of countries.
	 */
	countries = {
		/**
		 * Fetching countries. If new data is fetched, it will be stored locally.
		 *
		 * @param metaData Fetched metadata to determine whether new data should be fetched. This will be compared with locally stored metadata.
		 */
		fetch: async (metaData) => {
			let countriesInStorage = JSON.parse(this.#storage.getItem('countries'));

			if (!countriesInStorage || countriesInStorage.version !== metaData.version) {
				const {data} = await axios.get(this.#baseUri + metaData.url, this.#axiosCacheHeaders);
					
				if (metaData.version !== data.version) throw new Error("The application encountered a technical problem concerning fetching last static information about countries.");

				countriesInStorage = data;
				this.#storage.setItem('countries', JSON.stringify(countriesInStorage));
			}
		},
		
		get: () => JSON.parse(this.#storage.getItem('countries'))
	}
	
	/**
	 * Functions for local storage of coins.
	 */
	 coins = {
		/**
		 * Fetching coins. If new data is fetched, it will be stored locally.
		 *
		 * @param metaData Fetched metadata to determine whether new data should be fetched. This will be compared with locally stored metadata.
		 */
		fetch: async (metaData) => {
			await metaData.map(async (country) => {
				let coinsInStorage = JSON.parse(this.#storage.getItem(country.key + '_coins'));
				
				if (!coinsInStorage || coinsInStorage.version !== country.version) {
					const {data} = await axios.get(this.#baseUri + country.url, this.#axiosCacheHeaders);
					
					if (country.version !== data.version) throw new Error("The application encountered a technical problem concerning fetching last static information about coins from " + country.key + ".");
					
					coinsInStorage = data;
					this.#storage.setItem(country.key + '_coins', JSON.stringify(coinsInStorage));
				}
			})
		},
		
		get: (countryId) => JSON.parse(this.#storage.getItem(countryId + '_coins'))
	}
	
}

export default StaticDataStorage;