import { Auth } from 'aws-amplify';
import axios from 'axios';

/** Class representing storage for user data online. */
class UserDataOnlineStorage {
	//#baseUri = 'https://ov57df3w7g.execute-api.eu-central-1.amazonaws.com';
	#baseUri = 'https://api.v2.eurocoinhunt.eu';
	#axiosCacheHeaders = {'cache-control': 'max-age=0', 'content-Type': 'application/json'};
	#currentSession = null;

	/**
	 * Fetching all data for current user that is stored in the cloud. For now that means that all Collections, Friends and Messages are fetched.
	 *
	 * @returns All collections, friends and messages.
	 */
	fetch = async () => {
		this.#currentSession = await Auth.currentSession();
		
		try {
			const {data} = await axios.get(this.#baseUri + "/users/me", {
				"headers": {
					...this.#axiosCacheHeaders,
					'authorization': this.#currentSession.idToken.jwtToken
				}
			});
			return [data.collections, data.friends, data.messages];
		} catch (error) {
			throw error;
		}
	};
	
	collections = {
		store: async collection => {
			this.#currentSession = await Auth.currentSession();
			
			const response = await axios.put(this.#baseUri + "/users/me/collections/" + collection.id, collection, {
				"headers": {
					...this.#axiosCacheHeaders,
					'authorization': this.#currentSession.idToken.jwtToken
				}
			});
			
			if (response.status !== 200) throw new Error("Collection not stored correctly");
		}
	};
}

export default UserDataOnlineStorage;