import { Auth } from 'aws-amplify';

import Collection from './Collection';
import Friend from './Friend';
import Message from './Message';

import Storage from '../Storage/Storage';
import UserDataStorage from '../Storage/UserData/UserDataStorage';


/** All data for current user, including static data */
class MyData {
	#storage = new Storage();

	/**
	 * Fetch all stored data. If needed new data will be fetched from the cloud.
	 */	
	static fetch = async () => {
		// fetch userinfo from current authenticated user.		
		const userInfo = await Auth.currentUserInfo();
		let myData = new MyData(userInfo);

		// fetch all data about collections, friends and messages
		try {
			await myData.#storage.fetch();
			return myData;
		} catch (error) {
			throw error;
		}
	};

	/**
	 * Storing all collections, friends and messages.
	 */
	store = () => {
		this.collections.store();
		this.friends.store();
		this.messages.store();
	};
	
	/**
	 * Functions for friends.
	 */
	friends = {
		/**
		 * Count the number of current friends.
		 *
		 * @returns The amount of currents friends.
		 */
		count: () => this.#storage.friends.get().length,

		/**
		 * Get all current friends.
		 *
		 * @returns All current friends.
		 */
		get: () => this.#storage.friends.get().map(storedFriend => Friend.fromJSON(storedFriend)),
		
		/**
		 * Set all current friends.
		 *
		 * @param friends All current friends.
		 */
//		set: (friends) => this.#friends = friends,
		
		/**
		 * Add a new friend.
		 *
		 * @param friend The new friends.
		 */
//		add: (friend) => this.#friends.push(friend),
		
		/**
		 * Create a code with which a new friend can activate the friendship..
		 *
		 * @returns The code to use for activation of the friendship.
		 */
		createCode: () => Friend.createCode(),
		
		
//		store: () => this.#sessionStorage.setFriends(this.#friends),
//		
//		load: (friends) => {
//			try {
//				friends.forEach(friend => {
//					const convertedCollection = Friend.fromJSON(friend);
//					this.#friends.push(convertedCollection);
//				});
//			} catch(error) {
//				throw new Error("Friends could not be loaded");
//			}
//		}
	};

	/**
	 * Functions for collections.
	 */
	messages = {
		/**
		 * Count the number of messages.
		 *
		 * @returns The amount of messages.
		 */
		count: () => this.#storage.messages.get().length,
		
		/**
		 * Get all messages.
		 *
		 * @returns All messages.
		 */
		get: () => null,
		
//		store: () => this.#sessionStorage.setMessages(this.#messages),
//		load: (messages) => {
//			messages.forEach(message => {
//				this.#messages.push(new Message(message));
//			});
//		}
	};
	
	/**
	 * Functions for collections.
	 */
	collections = {
		/**
		 * Count the number of collections.
		 *
		 * @returns The amount of collections.
		 */
		count: () => this.#storage.collections.count(),
		
		/**
		 * Get all collections.
		 *
		 * @returns All collections.
		 */
		get: () => this.#storage.collections.get().map((storedCollection) => Collection.fromJSON(storedCollection)),
		
		/**
		 * Get a collection by its identifier.
		 *
		 * @param collectionId The identifier of the collection
		 * @returns A collection if one is found with given identifier.
		 */
		getById: (collectionId) => Collection.fromJSON(this.#storage.collections.getById(collectionId)),
		
		store: (collection) => this.#storage.collections.store(collection.toJSON()),
		

//		load: (collections) => {
//			try {
//				collections.forEach(collection => {
//					const convertedCollection = Collection.fromJSON(collection);
//					this.#collections.push(convertedCollection);
//				});
//			} catch(error) {
//				throw new Error("Collections could not be loaded");
//			}
//		},

		
		/**
		 * Get th index of a collection by its identifier.
		 *
		 * @param collectionId The identifier of the collection
		 * @returns The index of a collection if one is found with given identifier.
		 */
//		findIndexById: (collectionId) => this.#collections.findIndex(collection => collection.getId() === collectionId),
	

	
		/**
		 * Set collections.
		 *
		 * @param collections All collections to set.
		 */
//		set: (collections) => {
//			this.#collections = collections;
//			this.collections.store();
//		},
		
		/**
		 * Add a new collection. The new collections is stored instantly.
		 *
		 * @param collection The new collection.
		 */
//		add: (collection) => {
//			this.#collections.push(collection);
//			this.collections.store();
//		},

		/**
		 * Add a new collection. The new collections is stored instantly.
		 *
		 * @param collectionName The name of the new collection.
		 */
//		create: async (collectionName) => {
//			const userInfo = await Auth.currentUserInfo();
//			const collection = new Collection(collectionName, userInfo.attributes.sub, userInfo.username);
//			collection.store();
//			this.#collections.push(collection);
//			this.collections.store();
//		},
		
		/**
		 * Delete a collection by its Id.
		 *
		 * @param collectionId The id of the collection to delete
		 */
//		delete: (collectionId) => {
//			const index = this.collections.findIndexById(collectionId);
//			
//			if (index >= 0) {
//				this.#collections[index].delete();
//				this.#collections.splice(index, 1);
//				this.collections.store();
//			}
//		}		
	};
	
	countries = {
		get: () => this.#storage.countries.get()
	};
	
	coins = {
		get: (countryId) => this.#storage.coins.get(countryId),
	};
	
	#user;
	user = {
		get: () => this.#user
	};
}

export default MyData;