import { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { QuestionDiamondFill, CurrencyEuro } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import Help from './unsecured/Help';

import './Header.scss';

const Header = () => {
	return (
		<>
			<div id="header" style={{backgroundImage: 'url(/images/bg01.png)'}}>
				<span id="title"><span>€</span>uroCoinHunt</span>
				<QuickNavigation />
			</div>
		</>
	);
}

const QuickNavigation = () => {
	const [ showHelpModal, setHelpModal] = useState(false);
	const {t} = useTranslation();
	
	return <>
		<div id="QuickNavigation">
			<span id="helpButton" onClick={() => {setHelpModal(true)}}><QuestionDiamondFill/></span>
			<span id="loginButton"><Link to="collections" id="collectionsShortcutButton"><CurrencyEuro /></Link></span>
		</div>
		<Modal show={showHelpModal} onHide={() => setHelpModal(false)} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>{t("data.screens.help.modalTitle")}</Modal.Title>
			</Modal.Header>
			<Modal.Body><Help /></Modal.Body>
		</Modal>
	</>;
}

export default Header;