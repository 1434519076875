import { v4 as uuidv4 } from 'uuid';

class Collection {
	#id;
	#version;
	#name;
	#user = {
		id: null,
		name: null
	};
	#count = 0;
	#value = 0;
	#coins = {};
	#lastUpdate;
	
	constructor(name, userId, userName) {
		this.#id = uuidv4();
		this.#version = '2.0';  
		this.#name = name;
		this.#user.id = userId;
		this.#user.name = userName;
	}
	
	static fromJSON = (collection) => {
		if (!collection.name || !collection.user || !collection.user.id || !collection.user.name) {
			throw new Error("Name, userId and username are mandatory");
		}
		
		const convertedCollection = new Collection(collection.name, collection.user.id, collection.user.name);
		if (collection.id) {convertedCollection.#id = collection.id} else {throw new Error("ID is mandatory")};
		if (collection.version) {convertedCollection.#version = collection.version} else {throw new Error("Version is mandatory")};
		if (collection.count) {convertedCollection.#count = collection.count};
		if (collection.value) {convertedCollection.#value = collection.value};
		if (collection.coins) {convertedCollection.#coins = collection.coins};
		
		return convertedCollection;
	}
	
	toJSON = () => {
		return {
			id: this.#id,
			version: this.#version,
			name: this.#name,
			user: this.#user,
			count: this.#count,
			value: this.#value,
			coins: this.#coins,
			lastUpdate: this.#lastUpdate
		}
	}
	
	coins = {
		get: () => this.#coins,
		
		findIndex: (coinToFind) => {
			if (!this.#coins[coinToFind.country] || !this.#coins[coinToFind.country][coinToFind.type]) return -1;
			
			let primaryResult = this.#coins[coinToFind.country][coinToFind.type].findIndex(coin => {
				return (coinToFind.year && parseInt(coin.year) === parseInt(coinToFind.year.toString())) &&
					((!coinToFind.index && !coin.index) || (coinToFind.index && coin.index && coin.index === coinToFind.index)) &&
					((!coinToFind.edge && !coin.edge) || (coinToFind.edge && coin.edge && coin.edge === coinToFind.edge)) &&
					((!coinToFind.variant && !coin.variant) || (coinToFind.variant && coin.variant && coin.variant === coinToFind.variant)) &&
					((!coinToFind.variation && !coin.variation) || (coinToFind.variation && coin.variation && coin.variation === coinToFind.variation));
			});
			
			if (primaryResult > -1) return primaryResult;
			else if (primaryResult === -1 && coinToFind.country === "GR") {
				coinToFind["variation"] = coinToFind.variant;
				delete coinToFind.variation;
				
				return this.#coins[coinToFind.country][coinToFind.type].findIndex(coin => {
					return (coinToFind.year && parseInt(coin.year) === parseInt(coinToFind.year.toString())) &&
						((!coinToFind.index && !coin.index) || (coinToFind.index && coin.index && coin.index === coinToFind.index)) &&
						((!coinToFind.edge && !coin.edge) || (coinToFind.edge && coin.edge && coin.edge === coinToFind.edge)) &&
						((!coinToFind.variation && !coin.variation) || (coinToFind.variation && coin.variation && coin.variation === coinToFind.variation));
				});
			} else return -1; 
		},
			
		add: (coin) => {
			if (!this.#coins[coin.country]) this.#coins[coin.country] = {};
			if (!this.#coins[coin.country][coin.type]) this.#coins[coin.country][coin.type] = [];
			
			if (this.coins.findIndex(coin) === -1) {
				const coinToAdd = {...coin, insertionDate: Date.now()}
	
				this.#coins[coin.country][coin.type].push(coinToAdd);
				this.#count++;
				this.#value += coin.type === 'COM' ? 200 : parseInt(coin.type);
	
				return true;
			}
			
			return false;
		},
	
		remove: (coin) => {
			let result = false;

			const index = this.coins.findIndex(coin);
			
			if (index > -1) {
				this.#coins[coin.country][coin.type].splice(index, 1);
				this.#count--;
				this.#value -= coin.type === 'COM' ? 200 : parseInt(coin.type);
				
				result = true; 
			}

			return result;
		},
		
		countForCountry: (countryId) => {
			let count = 0
			
			if (this.#coins && this.#coins[countryId]) {
				if (this.#coins[countryId]["COM"]) count += this.#coins[countryId]["COM"].length;
				if (this.#coins[countryId]["200"]) count += this.#coins[countryId]["200"].length;
				if (this.#coins[countryId]["100"]) count += this.#coins[countryId]["100"].length;
				if (this.#coins[countryId]["050"]) count += this.#coins[countryId]["050"].length;
				if (this.#coins[countryId]["020"]) count += this.#coins[countryId]["020"].length;
				if (this.#coins[countryId]["010"]) count += this.#coins[countryId]["010"].length;
				if (this.#coins[countryId]["005"]) count += this.#coins[countryId]["005"].length;
				if (this.#coins[countryId]["002"]) count += this.#coins[countryId]["002"].length;
				if (this.#coins[countryId]["001"]) count += this.#coins[countryId]["001"].length;
			}
		
			return count;
		}
	}
	
	getId = () => this.#id;
	getName = () => this.#name;
	getCount = () => this.#count;
	getValue = () => this.#value;
}

export default Collection;